import { Fragment, useState, useContext } from "react";
import {
  Grid,
  Typography,
  Modal,
  Button,
  Card,
  CardContent,
} from "@mui/material";

import { UploadOutlined } from "@mui/icons-material";
import { BasicModal, UIAlert } from "../../components/spam/modal";
import { useTransactionUpload } from "../../services/transactions";
import gtw from "../../apis/gtw";
// import axios from "axios";
import { AuthContext } from "../../utils/auth";
import writeXlsxFile from "write-excel-file";
import { LinearProgressWithLabel } from "../../components/spam/inputs";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
// import axios from "axios";

export default function SearchAwb() {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false),
    // eslint-disable-next-line no-unused-vars
    [progress, setProgress] = useState(0),
    // eslint-disable-next-line no-unused-vars
    [dataError, setDataError] = useState([]),
    [res, setRes] = useState({ status: "", message: "" }),
    // eslint-disable-next-line no-unused-vars
    [checkError, setCheckError] = useState(true),
    { headers } = useContext(AuthContext),
    [openModal, setOpenmodal] = useState(false),
    // eslint-disable-next-line no-unused-vars
    { onProcess } = useTransactionUpload();

  function fileHandler(e) {
    const fileObj = e.target.files[0];

    if (!fileObj) {
      alert("Silakan pilih file");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      const binaryStr = event.target.result;

      try {
        const response = await gtw({
          url: "/sync/search/awb",
          // url: "http://localhost:5003/search-awb",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          data: JSON.stringify({ file: binaryStr.split(",")[1] }),
        });

        if (response.status !== 200) {
          throw new Error("Respons jaringan tidak OK");
        }

        const data = response.data;
        setRes({ status: "success", message: "Data berhasil diambil!" });

        // Proses data yang diterima
        console.log(data);
        downloadExcel(data);
      } catch (error) {
        console.error("Terjadi kesalahan saat mengambil data:", error);
        setRes({
          status: "error",
          message: "Terjadi kesalahan saat mengambil data.",
        });
      }
    };
    reader.readAsDataURL(fileObj);
  }

  const downloadExcel = async (response) => {
    // Pastikan kita menggunakan array data yang benar dari response
    const data = response.data; // Akses array yang sebenarnya

    // Periksa apakah data adalah array dan memiliki format yang benar
    if (
      !Array.isArray(data) ||
      data.length === 0 ||
      typeof data[0] !== "object"
    ) {
      console.error("Invalid data format for Excel download:", data);
      return;
    }

    // Definisikan skema untuk menulis file Excel
    const schema = [
      {
        column: "Airwaybill",
        type: String,
        value: (row) => row.airwaybill,
      },
      {
        column: "Seller Name",
        type: String,
        value: (row) => row.seller_name,
      },
      {
        column: "Shop Name",
        type: String,
        value: (row) => row.shop_name,
      },
      // Tambahkan kolom lain jika ada
    ];

    try {
      await writeXlsxFile(data, {
        schema,
        headerStyle: {
          fontWeight: "bold",
          align: "center",
        },
        fileName: "search-results.xlsx",
      });
    } catch (error) {
      console.error("Error writing Excel file:", error);
    }
  };

  const handleDownload = async () => {
    const schema = [
      {
        column: "Airwaybill",
        width: 13,
        type: String,
        value: () => "",
      },
    ];

    await writeXlsxFile([], {
      schema,
      headerStyle: {
        fontWeight: "bold",
        align: "center",
      },
      fileName: "template search-awb.xlsx",
    });
  };

  return (
    <Fragment>
      <UIAlert
        onClose={(_) => setRes({ status: "", message: "" })}
        open={res.status !== ""}
        autoHideDuration={10000}
        res={res}
      />
      <Modal
        open={openModal}
        sx={{
          width: "100%",
          justifyContent: "center",
          display: "flex",
          height: "70%",
          mt: 10,
        }}
      >
        <Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              backgroundColor: "white",
              height: 50,
            }}
          >
            <Button
              size="small"
              onClick={() => {
                setOpenmodal(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Grid>
          <Card
            sx={{
              width: "auto",
              overflow: "auto",
              height: "100%",
              borderRadius: 0,
            }}
          >
            <CardContent>
              {dataError.map((e, i) => (
                <Typography variant="body2" sx={{ mb: 5 }} key={i}>{`${
                  i + 1
                }. ${e}`}</Typography>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Modal>
      {loading ? (
        <BasicModal title="Loading..." open onClose={() => null}>
          <Grid
            container
            spacing={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item>
              {!checkError ? (
                <LinearProgressWithLabel value={progress} />
              ) : (
                <Grid
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress disableShrink />
                </Grid>
              )}
              <Typography variant="h6" color="red" textAlign="center">
                {checkError
                  ? "Proses pengecekan sedang berlangsung"
                  : "Proses upload sedang berlangsung"}
                <br />
                Jangan buka tab lain
              </Typography>
            </Grid>
          </Grid>
        </BasicModal>
      ) : null}

      {dataError.length ? (
        <Button onClick={() => setOpenmodal(true)}>Tampilkan Log</Button>
      ) : (
        <></>
      )}
      <Grid
        item
        width="100%"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        style={{
          height: "85vh",
          width: "100%",
          position: "relative",
          cursor: "pointer",
        }}
        display="flex"
      >
        <UploadOutlined style={{ width: "auto", opacity: 0.5, height: 250 }} />
        <Typography variant="h4">Upload File</Typography>

        <input
          type="file"
          onChange={(e) => {
            fileHandler(e);
            e.target.value = null;
          }}
          style={{
            width: "100%",
            cursor: "pointer",
            opacity: 0,
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />

        <Button
          variant="outlined"
          onClick={(e) => {
            handleDownload(".xlsx");
          }}
          sx={{ mt: 10 }}
        >
          Download Template
        </Button>
      </Grid>
    </Fragment>
  );
}
